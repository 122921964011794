import React from 'react'
import img from '../../images/endermomasaz-twarz-zmiana.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import Fade from 'react-reveal'
import {Seo} from '../../components/Seo'

const EndermomasazTwarz = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Endermomasaż | Modelowanie Sylwetki'
                    description='Endermomasaż twarzy jest zabiegiem dla wszystkich osób, które pragną
          zniwelować efekty starzenia, chcą mieć jędrną oraz elastyczną skórę,
          złagodzić obrzęki i spłycić zmarszczki, zniwelować cienie pod oczami i
          obwisłości skóry, a nawet zredukować podwójny podbródek.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-twarz/endermomasaz'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Endermomasaż</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Endermomasaż zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Endermomasaż twarzy jest zabiegiem dla wszystkich
                                        osób, które pragną zniwelować efekty starzenia,
                                        chcą mieć jędrną oraz elastyczną skórę, złagodzić
                                        obrzęki i spłycić zmarszczki, zniwelować cienie
                                        pod oczami i obwisłości skóry, a nawet zredukować
                                        podwójny podbródek. Endermomasaż to zabieg silnie
                                        ujędrniający, napinający i uelastyczniający skórę.
                                        Poprawia jej jakość i kondycję. Poprzez
                                        zastosowanie podciśnienia podczas zabiegu skóra
                                        zwiększa swoje ukrwienie, staje się bardziej
                                        dotleniona. Pojedynczy zabieg trwa 30 minut, jeśli
                                        zdecydujemy się go wykonać na twarz, szyję i
                                        dekolt lub 15 minut, jeśli wybierzemy zabieg na
                                        samą twarz. Zalecana jest seria zabiegów, dwa razy
                                        w tygodniu.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>ENDERMOMASAŻ</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ TWARZY - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>137 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ TWARZY -{' '}
                                                    <span>PAKIET 6 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>497 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ TWARZY -{' '}
                                                    <span>PAKIET 8 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>627 zł</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/beauty-face_5403120.htm'>
                    Image by pressfoto
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default EndermomasazTwarz
